import React from "react";
import { OrganizationData } from "../../type/responses/OrganizationData";
import { useTranslation } from 'react-i18next';
import CreateOrganization from "../CreateOrganization";
import { TANSTACK_ORGANIZATIONS_USER_KEY, useOrganizations } from "../../hooks/useOrganizations";
import { queryClient } from "../../App";
import AseclaButton from "../utils/AseclaButton";
import InvoiceData from "../../type/InvoiceData";
import Loading from "../utils/Loading";
import IsErrorMessage from "../utils/IsErrorMessage";
import ManageOrganizationData from "./ManageOrganizationData";
import { useRenameOrganization } from "../../hooks/mutations/useOrganizationOperations";
import styled from "styled-components";
import InputEditOnDemand from "../utils/InputEditOnDemand";
import TitleBanner from "../utils/TitleBanner";

function ManageOrganizations() {
    const {t} = useTranslation();
    const { organizations, isFetching, isError } = useOrganizations();
    const [ creatingOrganization, setCreatingOrganization ] = React.useState<boolean>(false);
    const [ organizationToEditInvoiceData, setOrganizationToEditInvoiceData] = React.useState<OrganizationData | null>(null);
    const [ organizationsInvoiceData, setOrganizationsInvoiceData] = React.useState<InvoiceData | null>(null);
    const { renameOrganization } = useRenameOrganization();

    if (isFetching) return <Loading></Loading>
    if (isError) return <IsErrorMessage></IsErrorMessage>

    if (organizationToEditInvoiceData != null) {
        return <ManageOrganizationData
            organization={organizationToEditInvoiceData}
            invoiceData={organizationsInvoiceData}
            setInvoiceData={setOrganizationsInvoiceData}
            onOrganizationSaved={setOrganizationToEditInvoiceData}
        />
    }

    if (creatingOrganization) {
        return <>
            <CreateOrganization onOrganizationCreated={() => {
                    setCreatingOrganization(false);
                    queryClient.invalidateQueries({queryKey: TANSTACK_ORGANIZATIONS_USER_KEY});
                }}
                cancelCreation={() => setCreatingOrganization(false)}
            ></CreateOrganization>
        </>
    }

    return <>
        <TitleBanner title={t("Your organizations")}></TitleBanner>
        {(organizations == null || organizations.length < 1) && <CreateOrganizationCTA>
                <AseclaButton action={e => setCreatingOrganization(true)}>{t("Create new Organization") as string}</AseclaButton>
            </CreateOrganizationCTA>
        }
        <div id="usersOrganizationList">
            {organizations?.map((h: OrganizationData) => <div key={"org_" + h.id}>
                    <span className="sideNote">(id: {h.id})</span>
                    <InputEditOnDemand onSaveAction={newName => renameOrganization({
                            organizationId: h.id,
                            newName,
                        })} buttonText={t("Rename")} value={h.name} key={h.name} disabled={!h.haveAdminRights}/>
                    {' '}
                    {h.haveManagerRights && <AseclaButton className="small" action={e => {setOrganizationToEditInvoiceData(h); setOrganizationsInvoiceData({...h.invoiceData});}}>{t("Edit invoice data") as string}</AseclaButton>}
                </div>
            )}
        </div>
        <br></br>
        
    </>
}
export default ManageOrganizations;

const CreateOrganizationCTA = styled.div`
    margin-top: 10px;
    margin-bottom: 30px;
`;